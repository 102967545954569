import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function ManagementAccounting() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Management Accounting &amp; KPIs
              </h1>
              <a href="accounting-&-taxation.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">
                Management Accounting &amp; KPIs
              </a>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Bookkeeping Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Know Your KPI
                  </h5>
                  <h1 className="mb-0">Management Accounting at Ease</h1>
                </div>
                <p className="mb-4">
                  We are your trusted partner in navigating the intricate world
                  of Management Accounting. Our expert team is committed to
                  helping your business thrive through strategic financial
                  planning and decision-making. Explore the pillars of our
                  services: Budgeting, Decision Making, Cost Accounting, and Key
                  Performance Indicators (KPIs).
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/management-accounting.jpg"
                    alt="management accounting"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bookeeping Start */}
        {/* Service Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                How it Works
              </h5>
              <h1 className="mb-0">List of Services &amp; Descriptions</h1>
            </div>
            <div className="row g-5">
              <div className="col-md-1" />
              <div
                className="col-lg-10 col-md-10 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="table-container">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>SERVICE</th>
                        <th>DESCRIPTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Budgeting</td>
                        <td>
                          At O.G.M, we specialize in crafting comprehensive
                          budgets tailored to your business objectives. Our
                          meticulous approach ensures optimal resource
                          allocation, helping you achieve financial goals with
                          precision. Trust us to guide you on the path to fiscal
                          responsibility and success.
                        </td>
                      </tr>
                      <tr>
                        <td>Decision Making</td>
                        <td>
                          In the fast-paced business environment, making
                          informed decisions is paramount. O.G.M Consultants
                          provide strategic insights backed by accurate data and
                          analysis, empowering you to navigate challenges and
                          seize opportunities confidently. Your success is our
                          priority, and informed decisions are the key.
                        </td>
                      </tr>
                      <tr>
                        <td>Cost Accounting</td>
                        <td>
                          Gain control over your finances with our Cost
                          Accounting services. We delve deep into your expenses,
                          offering a granular understanding of where your
                          resources are allocated. Our strategies ensure cost
                          efficiency, allowing you to optimize spending without
                          compromising on quality or growth.
                        </td>
                      </tr>
                      <tr>
                        <td>KPIs</td>
                        <td>
                          Unlock the potential of your business with Key
                          Performance Indicators (KPIs). O.G.M's experts
                          identify and implement measurable metrics to gauge
                          organizational performance. Stay on course and achieve
                          your objectives with our KPI-driven approach, turning
                          insights into actionable strategies for success.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default ManagementAccounting;
