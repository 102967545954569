import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function BusinessPlanning() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Business Plan
              </h1>
              <a href="accounting-&-taxation.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Business Plan</a>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Bookkeeping Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Tailored Business Plan
                  </h5>
                  <h1 className="mb-0">
                    Strategize Your Business to Run Smoothly and Streamlined
                  </h1>
                </div>
                <p className="mb-4">
                  At O.G.M Consultants, we understand that a well-thought-out
                  business plan is the cornerstone of success for any venture.
                  Whether you're a startup looking to secure funding or an
                  established business aiming to refine your strategy, our
                  Business Planning Service is designed to guide you through the
                  intricacies of strategic planning and model development.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/business-planning.jpg"
                    alt="business planning"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bookeeping Start */}
        {/* Service Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                More About the Service
              </h5>
              <h1 className="mb-0">
                Our Business Planning Services are as Follows
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-md-1" />
              <div
                className="col-lg-10 col-md-10 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="table-container">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>SECTION</th>
                        <th>DESCRIPTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Executive Summary</td>
                        <td>
                          A concise overview of your business, highlighting key
                          aspects such as mission, vision, and objectives.
                        </td>
                      </tr>
                      <tr>
                        <td>Company Description</td>
                        <td>
                          Detailed information about your company's history,
                          structure, and core values.
                        </td>
                      </tr>
                      <tr>
                        <td>Market Analysis</td>
                        <td>
                          In-depth research on your target market, industry
                          trends, and competitive landscape.
                        </td>
                      </tr>
                      <tr>
                        <td>Business Model</td>
                        <td>
                          A clear outline of your business model, including
                          value proposition, customer segments, and revenue
                          streams.
                        </td>
                      </tr>
                      <tr>
                        <td>Marketing and Sales Strategy</td>
                        <td>
                          Plans for reaching your target audience, promoting
                          your products/services, and driving sales.
                        </td>
                      </tr>
                      <tr>
                        <td>Organizational Structure</td>
                        <td>
                          Details on the hierarchy, roles, and responsibilities
                          within your organization.
                        </td>
                      </tr>
                      <tr>
                        <td>Financial Projections</td>
                        <td>
                          Comprehensive forecasts covering income statements,
                          balance sheets, and cash flow projections.
                        </td>
                      </tr>
                      <tr>
                        <td>Risk Analysis</td>
                        <td>
                          Identification and mitigation strategies for potential
                          risks that could impact your business.
                        </td>
                      </tr>
                      <tr>
                        <td>Implementation Plan</td>
                        <td>
                          Step-by-step guide on how to execute your business
                          plan, including timelines and milestones.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default BusinessPlanning;
