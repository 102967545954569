import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import Footer from "../components/Footer/Footer";

function AuditSupport() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Audit &amp; Support
              </h1>
              <a href="index.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Audit &amp; Support</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* About Start */}
        <div
          className="container-fluid py-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Overview</h1>
                </div>
                <p className="mb-4">
                  At O.G.M. Consultants, we understand the critical role audits
                  play in ensuring regulatory compliance, identifying
                  operational inefficiencies, and improving overall business
                  performance. Our comprehensive audit and audit support
                  services are tailored to meet the unique needs of our clients
                  across various industries.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/audit-and-audit-support.jpg"
                    alt="audit and audit support"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Our Services
              </h5>
              <h1 className="mb-0">The Service Categories</h1>
            </div>
            <div className="row g-5">
              <div
                className="col-lg-3 col-md-3 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-building text-white" />
                  </div>
                  <h4 className="mb-3">Financial Audit</h4>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-chart-pie text-white" />
                  </div>
                  <h4 className="mb-3">Operational Audit</h4>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 wow zoomIn"
                data-wow-delay="0.9s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-balance-scale text-white" />
                  </div>
                  <h4 className="mb-3">Internal Audit</h4>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-users text-white" />
                  </div>
                  <h4 className="mb-3">Compliance Audit</h4>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-question-circle text-white" />
                  </div>
                  <h4 className="mb-3">Information Technology Audit</h4>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-question-circle text-white" />
                  </div>
                  <h4 className="mb-3">Preparation Assistance</h4>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-question-circle text-white" />
                  </div>
                  <h4 className="mb-3">Audit Representation</h4>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-question-circle text-white" />
                  </div>
                  <h4 className="mb-3">Preparation Assistance</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default AuditSupport;
