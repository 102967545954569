import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function EstoniaCompany() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Estonia Company Formation
              </h1>
              <a href="business-setup.html" className="h5 text-white">
                Business Setup
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Estonia</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Cayman Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">
                    Your Expert Partner in Estonia Company Formation
                  </h1>
                </div>
                <p className="mb-4">
                  Are you considering expanding your business to Europe?
                  Estonia, with its thriving digital ecosystem, transparent
                  business environment, and favorable tax policies, stands out
                  as an excellent choice for company formation. At O.G.M
                  Consultants, we specialize in guiding you through the seamless
                  process of establishing your business in Estonia, ensuring you
                  enjoy the numerous benefits that come with it.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "250px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/estonia-company-formation.jpg"
                    alt="estonia company formation.jpg"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cayman End */}
        {/* Reuired Docs Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Why Estonia
              </h5>
              <h1 className="mb-0">
                Why Choose Estonia for Your Company Formation?
              </h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Digital Advancements</h4>
                  <p className="m-0">
                    Estonia is renowned for its advanced digital infrastructure.
                    The country embraces cutting-edge technology, offering a
                    paperless and efficient business environment. This digital
                    approach streamlines administrative processes, making it
                    convenient for entrepreneurs to manage their companies
                    remotely.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Favorable Tax Environment</h4>
                  <p className="m-0">
                    Estonia boasts one of the most attractive tax systems in
                    Europe. With a flat corporate income tax rate of 20%, no VAT
                    on retained earnings, and no tax on reinvested profits, it
                    provides an optimal environment for businesses seeking tax
                    efficiency.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">E-Residency Program</h4>
                  <p className="m-0">
                    Estonia's E-Residency program allows non-residents to
                    establish and manage a business online. This unique feature
                    enables entrepreneurs to conduct business globally without
                    physically residing in Estonia, opening up a world of
                    possibilities for international business owners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Reuired Docs End */}
        {/* Cost Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">
                    Legal Types of Companies for Residents and Non-Residents
                  </h1>
                </div>
                <div className="row g-0 mb-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      For Residents
                    </h5>
                    <h5 className="fw-bold text-primary text-uppercase">
                      Osaühing (OÜ) - Private Limited Company
                    </h5>
                    <li>Minimum share capital: 2,500 EUR.</li>
                    <li>
                      Limited liability, providing financial protection for
                      shareholders.
                    </li>
                    <li>Suitable for small to medium-sized enterprises.</li>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      For Non-Residents
                    </h5>
                    <h5 className="fw-bold text-primary text-uppercase">
                      Estonian Branch
                    </h5>
                    <li>
                      Ideal for companies expanding their operations to Estonia.
                    </li>
                    <li>
                      The parent company is liable for the Estonian branch.
                    </li>
                  </div>
                </div>
              </div>
              <div className="col-lg-5" style={{ "min-height": "400px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/legal-companies-estonia.jpg"
                    alt="legal companies estonia"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cost End */}
        {/* Reuired Docs Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Requirements
              </h5>
              <h1 className="mb-0">Requirements for Company Formation</h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Resident Company</h4>
                  <ul>
                    <li>At least one shareholder and one director.</li>
                    <li>Registered office address in Estonia.</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Non-Resident Company</h4>
                  <ul>
                    <li>Registration documents from the home country.</li>
                    <li>Legalized power of attorney for representation.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Reuired Docs End */}
        {/* Cost Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Estimated Costs</h1>
                </div>
                <div className="row g-0 mb-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Resident Company
                    </h5>
                    <ul>
                      <li>Government fees: 190 EUR.</li>
                      <li>Notary fees: Approximately 200-300 EUR.</li>
                      <li>Ongoing maintenance costs.</li>
                    </ul>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Non-Resident Company
                    </h5>
                    <ul>
                      <li>Government fees: 190 EUR.</li>
                      <li>Legalization and translation costs for documents.</li>
                      <li>Ongoing maintenance costs.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-5" style={{ "min-height": "200px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/estonia-company-formation-duration.jpg"
                    alt="estonia company formation duration"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cost End */}
        {/* E Residency */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-5">
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/estonia-e-residency.png"
                    alt="estonia e-residency"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7" style={{ "min-height": "200px" }}>
                <div className="section-title position-relative pb-3 mb-5">
                  <h1 className="mb-0">E- Residency</h1>
                </div>
                <p>
                  The E-Residency program allows non-residents to access
                  Estonian digital services, including establishing and managing
                  a company online. This innovative solution enables
                  entrepreneurs to conduct business globally with ease.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Cost End */}
        {/* Reuired Docs Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Extra Options
              </h5>
              <h1 className="mb-0">
                Office Address &amp; Bank Account Opening Options
              </h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Office Address</h4>
                  <p>
                    We assist in providing a registered office address in
                    Estonia, fulfilling the legal requirement for company
                    registration.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Bank Account Opening</h4>
                  <p>
                    We guide you through the process of opening a business bank
                    account in Estonia, ensuring smooth financial transactions
                    for your company.
                  </p>
                </div>
              </div>
              <p className="text-center position-relative mx-auto col-md-8">
                At O.G.M Consultants, we are committed to making your company
                formation process in Estonia straightforward and efficient.
                Contact us today, and let us be your partner in establishing and
                growing your business in this thriving European hub.
              </p>
            </div>
          </div>
        </div>
        {/* Reuired Docs End */}
        {/* Footer Start */}

        {/* Footer End */}
      </div>
    </Fragment>
  );
}
export default EstoniaCompany;
