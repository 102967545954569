import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function BusinessValuation() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>Business Valuation Services in Dubai | OGM Consultants</title>
          <meta
            name="description"
            content="One of the top Business Valuation Services in Dubai is provided by OGM Consultants, which can help business owners move forward in the valuation process and comprehend its importance. Contact us today!"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Business Valuation
              </h1>
              <a href="accounting-&-taxation.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Business Valuation</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Bookkeeping Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Valuation Insight
                  </h5>
                  <h1 className="mb-0">
                    We Unlock Strategic Insights for Success in Our Business
                    Valuation Services
                  </h1>
                </div>
                <p className="mb-4">
                  Welcome to O.G.M Consultants, your trusted partner in business
                  valuation. Understanding the true worth of your business is
                  essential for making informed decisions, whether you're
                  planning <b>to sell, seeking funding</b>, or{" "}
                  <b>strategizing for the future</b>.
                </p>
                <p>
                  Conducting a business valuation with O.G.M Consultants
                  provides you with a clear and accurate assessment of your
                  company's value, enabling you to showcase its strengths,
                  attract investors, and identify areas for improvement.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/business-valuation.jpg"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bookeeping Start */}
        {/* Service Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Our Well-Curated Approach
              </h5>
              <h1 className="mb-0">
                Experts in Comprehensive Business Valuation.
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-md-1" />
              <div
                className="col-lg-10 col-md-10 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="table-container">
                  <p>
                    Our experienced consultants utilize a variety of business
                    valuation methods, including market-based, income-based, and
                    asset-based approaches, ensuring a comprehensive evaluation
                    that aligns with your industry and business model.
                  </p>
                  <p>
                    Trust O.G.M Consultants to guide you through the valuation
                    process, empowering you with the insights needed to propel
                    your business forward.
                  </p>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default BusinessValuation;
