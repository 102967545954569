import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function UsaCompany() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>
            Company Formation in the USA | Company Formation in USA for Non
            Residents{" "}
          </title>
          <meta
            name="description"
            content="Looking for company formation in the USA? From Dubai. OGM Consultant  has expertise in facilitating the seamless formation of companies in the USA directly from Dubai. To know more about our services Contact us +971 50 986 0136"
          />{" "}
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                USA Company Formation
              </h1>
              <a href="index.html" className="h5 text-white">
                Business Setup
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">
                United States Of America
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* About Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Delaware
                  </h5>
                  <h1 className="mb-0">
                    We Are Your Best Connection For USA Company Formation
                  </h1>
                </div>
                <p className="mb-4">
                  Welcome to our comprehensive guide on Delaware company
                  formation, designed especially for non-US residents looking to
                  establish a business presence in the United States. Delaware
                  is a popular choice for company formation due to its
                  business-friendly environment, robust legal system, and tax
                  advantages. Whether you're an entrepreneur, investor, or a
                  business professional, this guide will walk you through the
                  essential steps, legal entities, required documents, and cost
                  breakdowns to make the process seamless.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/usa-company-formation.jpg"
                    alt="usa company formation"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Service Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">Entities</h5>
              <h1 className="mb-0">Types of Legal Entities</h1>
            </div>
            <div className="row g-5">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <h5 className="fw-bold text-primary text-uppercase">
                  Limited Liability Company (LLC)
                </h5>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Advantages</th>
                      <th>Requirements</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Flexibility, pass-through taxation, limited liability
                        protection.
                      </td>
                      <td>One or more members, an operating agreement.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <h5 className="fw-bold text-primary text-uppercase">
                  Corporation
                </h5>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Advantages</th>
                      <th>Requirements</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Limited liability, easy transfer of ownership, potential
                        for public offerings.
                      </td>
                      <td>Shareholders, directors, officers, bylaws.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <h5 className="fw-bold text-primary text-uppercase">
                  Limited Partnership (LP)
                </h5>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Advantages</th>
                      <th>Requirements</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Limited liability for some partners, pass-through
                        taxation.
                      </td>
                      <td>
                        General and limited partners, partnership agreement.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <h5 className="fw-bold text-primary text-uppercase">
                  C-Corporation
                </h5>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Advantages</th>
                      <th>Requirements</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Limited liability, potential for public offerings,
                        separate legal entity.
                      </td>
                      <td>Shareholders, directors, officers, bylaws.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Service End */}
        {/* Pricing Plan Start*/}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Pricing Plans
              </h5>
              <h1 className="mb-0">
                Offering Competitive Prices for USA Company Formation With 3
                Value-Packed Options!
              </h1>
            </div>
            <div className="row g-0">
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div className="bg-light rounded">
                  <div className="border-bottom py-4 px-5 mb-4">
                    <h4 className="text-success mb-1">Green</h4>
                    <small className="text-uppercase">
                      Forming an LLC or corporation in the most cost-effective
                      and environmentally conscious manner.
                    </small>
                  </div>
                  <div className="p-5 pt-0">
                    <h1 className="display-5 mb-3">
                      <small
                        className="align-top"
                        style={{ "font-size": "22px", "line-height": "45px" }}
                      >
                        $
                      </small>
                      599.00
                      <small
                        className="align-bottom"
                        style={{ "font-size": "16px", "line-height": "40px" }}
                      >
                        / One Time Fees
                      </small>
                    </h1>
                    <div className="d-flex justify-content-between mb-3">
                      <span>All Delaware Fees</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Registered Agent Fee</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Name Check &amp; Clearance</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Preparation of Documents</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Same-day Electronic Filing</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Certificate of Formation/Incorporation</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Digital Copy of Documents</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Digital Corporate Seal</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>
                        One Complete Year (12 Full Months) of Delaware
                        Registered Agent Service
                      </span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Lowest Delaware Registered Agent Fee</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <a href="Quote" className="btn btn-primary py-2 px-4 mt-4">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div
                  className="bg-white rounded shadow position-relative"
                  style={{ "z-index": "1" }}
                >
                  <div className="border-bottom py-4 px-5 mb-4">
                    <h4 className="text-primary mb-1">Basic</h4>
                    <small className="text-uppercase">
                      Everything you need to get started, plus digital document
                      templates with all the essentials.
                    </small>
                  </div>
                  <div className="p-5 pt-0">
                    <h1 className="display-5 mb-3">
                      <small
                        className="align-top"
                        style={{ "font-size": "22px", "line-height": "45px" }}
                      >
                        $
                      </small>
                      699.00
                      <small
                        className="align-bottom"
                        style={{ "font-size": "16px", "line-height": "40px" }}
                      >
                        / One Time Fees
                      </small>
                    </h1>
                    <div className="d-flex justify-content-between mb-3">
                      <span>
                        Everything you see listed in the&nbsp;Green package, +
                      </span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>
                        Certified Copy of Certificate of Formation/Incorporation
                        with Gold Seal or with Apostille
                      </span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Hard Copy of Documents</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>All Courier Fees</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Operating Agreements/Bylaws</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>LLC/Corporation Essential Docs</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Digital Membership/Stock Certificates</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Bank Resolution Form</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    {/* <div className="d-flex justify-content-between mb-3">
                      <span>Gold Embossed Binder</span>
                      <i className="fa fa-times text-danger pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>10 Membership/Stock Certificates</span>
                      <i className="fa fa-times text-danger pt-1" />
                    </div> */}
                    <a href="Quote" className="btn btn-primary py-2 px-4 mt-4">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="bg-light rounded">
                  <div className="border-bottom py-4 px-5 mb-4">
                    <h4 className="text-basic mb-1">Standard</h4>
                    <small className="text-uppercase">
                      The Basic package, plus a deluxe LLC kit and corporate
                      seal. <br /> Deluxe LLC/Corporate Kit:{" "}
                    </small>
                  </div>
                  <div className="p-5 pt-0">
                    <h1 className="display-5 mb-3">
                      <small
                        className="align-top"
                        style={{ "font-size": "22px", "line-height": "45px" }}
                      >
                        $
                      </small>
                      999.00
                      <small
                        className="align-bottom"
                        style={{ "font-size": "16px", "line-height": "40px" }}
                      >
                        / One Time Fees
                      </small>
                    </h1>
                    <div className="d-flex justify-content-between mb-3">
                      <span>All of Green Package</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>All of Basic Package</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Gold Embossed Binder</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Company Guidebook</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Operating Agreement/By-laws</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Meeting Minute Book</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Organizational Resolutions</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>10 Membership/Stock Certificates</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Membership/Stock Ledger</span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <span>
                        Corporate Seal . Fold-up style embosser with lead-cast
                        lettering and vinyl carrying case
                      </span>
                      <i className="fa fa-check text-primary pt-1" />
                    </div>
                    <a href="Quote" className="btn btn-primary py-2 px-4 mt-4">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Pricing Plan End */}
        {/* Reuired Docs Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Requirements
              </h5>
              <h1 className="mb-0">Required Documents for Non- US Residents</h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Copy of Passport</h4>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Proof of Address</h4>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Email ID</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Reuired Docs End */}
        {/* Get Started Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Checkout
                  </h5>
                  <h1 className="mb-0">What's Included in All Packages</h1>
                </div>
                <ul>
                  <li>Name Check &amp; Clearance</li>
                  <li>Certificate of Incorporation/Formation</li>
                  <li>Preparation of Documents</li>
                  <li>All Delaware Filing Fees</li>
                  <li>Same Day Electronic Filing</li>
                  <li>Registered Agent Fee - 12 Months</li>
                  <li>Email of Approved Documents</li>
                  <li>Free Shipping &amp; Handling</li>
                  <li>FREE Lifetime Customer Support</li>
                  <li>And much more</li>
                </ul>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/start-company-in-usa.jpg"
                    alt="start company in usa"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Get Started End */}
        {/* Annual Renewal Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/tax-policy.jpg"
                    alt="tax-policy"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Checkout
                  </h5>
                  <h1 className="mb-0">Annual Renewal &amp; Tax Policy</h1>
                </div>
                <ul>
                  <li>
                    When you incorporate in Delaware, we guarantee your annual
                    Delaware Registered Agent Fee will remain fixed at $50 per
                    company, per year, for the life of your company.
                  </li>
                  <li>
                    If you are non- USA Resident then there is no income tax for
                    your company, however, there is Franchise Tax of $ 300
                    should be paid annually.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Annual Renewal End */}
        {/* Enquiry Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Have an Enquiry?
              </h5>
              <h1 className="mb-0">
                Do Not Hesitate To Contact Us For Your Free Consultation &amp;
                Quotations
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-md-3" />
              <div
                className=" col-md-6 col-md-offset-3 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <form action="https://formspree.io/f/xjvnjnea" method="post">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="f_name"
                        placeholder="First Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="s_name"
                        placeholder="Last Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        className="form-control border-0 bg-light px-4"
                        name="Email ID"
                        placeholder="Email ID"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="c_num"
                        placeholder="Contact Number"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control border-0 bg-light px-4 py-3"
                        rows={4}
                        name="Message"
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-3" />
            </div>
          </div>
        </div>
        {/* Enquiry End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default UsaCompany;
