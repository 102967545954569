import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function EcommerceBusiness() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                E-Commerce Company Formation
              </h1>
              <a href="index.html" className="h5 text-white">
                Business Setup
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">E-Commerce</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* About Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">
                    Your Premier Partner in <br />
                    E-Commerce Business Formation.
                  </h1>
                </div>
                <p className="mb-4">
                  Are you ready to embark on a thrilling journey into the world
                  of online retail? Look no further than O.G.M Consultants, your
                  trusted ally in E-commerce business formation. Whether you're
                  eyeing the vast marketplaces of Amazon or planning to set up
                  shop on popular platforms like Shopify or dive into the
                  lucrative realm of dropshipping, we've got you covered.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/e-commerce.jpg"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Dubai Business Formation */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                What We Do
              </h5>
              <h1 className="mb-0">E-Commerce Business Formation Services</h1>
            </div>
            <div className="row g-5">
              {/* Amazon */}
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">
                    Amazon E-Commerce Business Registration
                  </h1>
                </div>
                <p className="mb-4">
                  Unlock the potential of the world's largest online marketplace
                  with our comprehensive Amazon E-commerce business registration
                  services. Our expert team will guide you through the intricate
                  process, ensuring a seamless and hassle-free registration on
                  Amazon. From navigating seller account setup to optimizing
                  product listings, we empower you to maximize your presence and
                  sales on this e-commerce giant.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/amazon-e-commerce.jpeg"
                    alt="amazon e-commerce"
                    style={{ "object-fit": "fill" }}
                  />
                </div>
              </div>
              {/* Global Expansion */}
              <div className="col-lg-5">
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/global-business-formation.jpg"
                    alt="global business formation"
                    style={{ "object-fit": "fill" }}
                  />
                </div>
              </div>
              <div className="col-lg-7" style={{ "min-height": "300px" }}>
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Global Expansion Services</h1>
                  <p className="mb-4">
                    Expand your E-commerce footprint beyond borders with O.G.M
                    Consultants. We specialize in business formation services
                    across the USA, Canada, Australia, UK, Europe, and the
                    Middle East. Our international expertise ensures that you
                    navigate the complexities of different markets effortlessly,
                    allowing your business to thrive on a global scale.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dubai Business Formation Ends*/}
        {/* Service Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Getting Started
              </h5>
              <h1 className="mb-0">Launching Your First E-Commerce Store</h1>
            </div>
            <div className="row g-5">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <h4 className="mb-3">Shopify Setup and Optimization</h4>
                  <p className="m-0">
                    Ready to create your online store? Shopify is an excellent
                    choice for entrepreneurs seeking a user-friendly platform
                    with robust features. Our experts will guide you through the
                    process of setting up your Shopify store, from choosing the
                    right theme to configuring payment gateways and implementing
                    effective marketing strategies. Launching your first
                    E-commerce store has never been easier.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <h4 className="mb-3">Dropshipping Business Guidance</h4>
                  <p className="m-0">
                    Explore the world of drop shipping with O.G.M Consultants.
                    We provide in-depth guidance on starting and managing a
                    successful dropshipping business. From selecting reliable
                    suppliers to optimizing order fulfillment processes, our
                    team equips you with the knowledge and tools needed to
                    thrive in this dynamic E-commerce model.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service End */}
        {/* Enquiry Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Have an Enquiry?
              </h5>
              <h1 className="mb-0">
                Do Not Hesitate To Contact Us For Your Free Consultation &amp;
                Quotations
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-md-3" />
              <div
                className=" col-md-6 col-md-offset-3 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <form action="https://formspree.io/f/xjvnjnea" method="post">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="f_name"
                        placeholder="First Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="s_name"
                        placeholder="Last Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        className="form-control border-0 bg-light px-4"
                        name="Email ID"
                        placeholder="Email ID"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="c_num"
                        placeholder="Contact Number"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control border-0 bg-light px-4 py-3"
                        rows={4}
                        name="Message"
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-3" />
            </div>
          </div>
        </div>
        {/* Enquiry End */}
        {/* Footer Start */}

        {/* Footer End */}
      </div>
    </Fragment>
  );
}
export default EcommerceBusiness;
