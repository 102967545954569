import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function TransferPricing() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>Transfer Pricing Services in Dubai | OGM Consultants</title>
          <meta
            name="description"
            content="Searching for Transfer Pricing Services in Dubai? We are provided by a team of experienced professionals who have deep expertise in Global Transfer Pricing  regulation . To know more about us Contact us today!"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Transfer Pricing
              </h1>
              <a href="accounting-&-taxation.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Transfer Pricing</a>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Bookkeeping Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">
                    Strategic Transfer Pricing Solutions for Optimal Financial
                    Performance and Compliance.
                  </h1>
                </div>
                <p className="mb-4">
                  At O.G.M. Consultants, we understand the complexities and
                  challenges businesses face in the global marketplace. As
                  companies expand their operations across borders, the
                  intricacies of transfer pricing become increasingly critical.
                  Our dedicated team of experts is here to guide you through the
                  intricacies of transfer pricing, ensuring compliance with
                  regulations and optimizing your global tax position.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/transfer_pricing1.jpg"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bookeeping Start */}
        {/* Service Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">Highlight</h5>
              <h1 className="mb-0">What is Transfer Pricing</h1>
            </div>
            <div className="row g-5">
              <div className="col-md-1" />
              <div
                className="col-lg-10 col-md-10 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="table-container">
                  <p>
                    Transfer pricing refers to the pricing of goods, services,
                    or intellectual property transferred between entities within
                    the same multinational group. As businesses operate across
                    borders, they engage in transactions with their affiliated
                    entities. It is crucial to establish arm's length prices for
                    these transactions to ensure fair value and compliance with
                    tax regulations in different jurisdictions.
                  </p>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
        {/* Reuired Docs Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h1 className="mb-0">Our Expertise</h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Compliance &amp; Risk Mitigation</h4>
                  <p>
                    Navigating the ever-evolving landscape of transfer pricing
                    regulations is a complex task. Our team stays abreast of the
                    latest developments to ensure your business remains
                    compliant. We help you identify and mitigate potential
                    risks, ensuring a robust and defensible transfer pricing
                    strategy
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Documentation &amp; Reporting</h4>
                  <p>
                    Proper documentation is fundamental in demonstrating
                    compliance with transfer pricing regulations. Our
                    consultants work closely with your team to prepare
                    comprehensive documentation that not only meets regulatory
                    requirements but also serves as a valuable tool for
                    strategic decision-making.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">
                    Customized Transfer Pricing Strategies
                  </h4>
                  <p>
                    There is no one-size-fits-all approach to transfer pricing.
                    We tailor our strategies to align with your business
                    objectives, industry dynamics, and the specific challenges
                    you face. Our goal is to optimize your global tax position
                    while minimizing the risk of disputes with tax authorities.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Advance Pricing Agreements (APAs)</h4>
                  <p>
                    For businesses seeking certainty and predictability in their
                    transfer pricing arrangements, we assist in negotiating and
                    securing Advance Pricing Agreements with tax authorities.
                    APAs provide a proactive and agreed-upon framework for
                    transfer pricing, reducing the risk of disputes in the
                    future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">Why OGM?</h5>
              <h1 className="mb-0">
                Our Transfer Pricing is Peculiar &amp; Unique in the Below Ways
              </h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Industry Expertise</h4>
                  <p className="m-0">
                    Our consultants bring a wealth of experience across various
                    industries, allowing us to understand the unique challenges
                    each sector faces in the realm of transfer pricing.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Global Perspective</h4>
                  <p className="m-0">
                    Operating in a globalized world requires a global
                    perspective. We leverage our international expertise to help
                    you navigate the complexities of transfer pricing in
                    different jurisdictions.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Proactive Approach</h4>
                  <p className="m-0">
                    We don't just react to regulatory changes; we anticipate
                    them. Our proactive approach ensures that your transfer
                    pricing strategies are ahead of the curve, minimizing risks
                    and optimizing tax outcomes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
        {/* JavaScript Libraries */}
      </div>
    </Fragment>
  );
}
export default TransferPricing;
