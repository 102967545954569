import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function Advisory() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>
            IFRS Consulting Services in Dubai | IFRS Advisory Services in Dubai
          </title>
          <meta
            name="description"
            content=" Expert IIFRS Consulting Services in Dubai OGM Consultants provide Complete IFRS guidance to help your business stay compliant and Competitive. To know more about us Contact us on +971 50 986 0136"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                IFRS Compliance
              </h1>
              <a href="index.html" className="h5 text-white">
                Statutory &amp; Complaince
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">IFRS Compliance</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">Expert IFRS Advisory Service</h1>
                </div>
                <p className="mb-4">
                  At O.G.M. Consultants, we understand the complexities and
                  challenges that businesses face in navigating the
                  ever-evolving landscape of International Financial Reporting
                  Standards (IFRSs). With our dedicated team of experts, we
                  offer comprehensive IFRS advisory services tailored to meet
                  the unique needs of your organization. Whether you're a
                  multinational corporation or a growing enterprise, we're here
                  to provide you with the guidance and support necessary to
                  ensure compliance and optimize financial reporting practices.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/ifrs-advisory.jpeg"
                    alt="ifrs advisory"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">Scope</h5>
              <h1 className="mb-0">Our IFRS Advisory Services Include</h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">IFRS Implementation Assistance</h4>
                  <p className="m-0">
                    Transitioning to IFRS can be a daunting task. Our team
                    provides hands-on support throughout the implementation
                    process, from initial assessment to final adoption, helping
                    you seamlessly integrate IFRS into your financial reporting
                    framework.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">IFRS Training and Workshops</h4>
                  <p className="m-0">
                    Stay ahead of the curve with our specialized IFRS training
                    programs and workshops. Designed for finance professionals
                    at all levels, our interactive sessions cover key IFRS
                    principles, updates, and best practices to enhance your
                    team's expertise and proficiency.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">IFRS Compliance Reviews</h4>
                  <p className="m-0">
                    Ensure accuracy and consistency in your financial statements
                    with our thorough IFRS compliance reviews. Our experienced
                    consultants meticulously examine your reporting practices,
                    identifying areas for improvement and providing actionable
                    recommendations to mitigate risks and enhance transparency.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">IFRS Advisory Support</h4>
                  <p className="m-0">
                    Whether you need assistance with complex accounting
                    transactions, interpretation of IFRS standards, or strategic
                    advice on financial reporting matters, our seasoned advisors
                    are here to help. We offer personalized guidance and
                    practical solutions to address your specific challenges and
                    achieve your business objectives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Starts */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h1 className="mb-0">FAQs</h1>
                </div>
                <h5 className="fw-bold text-primary text-uppercase">
                  What are International Financial Reporting Standards (IFRSs)?
                </h5>
                <p className="mb-5">
                  IFRSs are a set of accounting standards developed by the
                  International Accounting Standards Board (IASB) to establish a
                  common global language for financial reporting. They are
                  designed to enhance transparency, comparability, and
                  reliability in financial statements, facilitating better
                  decision-making for investors and stakeholders.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  Why is compliance with IFRS important for my business?
                </h5>
                <p className="mb-5">
                  Compliance with IFRS is essential for companies operating in
                  international markets or seeking access to global capital. It
                  enhances the credibility of financial statements, improves
                  transparency, and fosters investor confidence. Moreover,
                  adhering to IFRS ensures consistency and comparability in
                  financial reporting, enabling stakeholders to make informed
                  decisions.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How can O.G.M. Consultants help my organization with IFRS
                  compliance?
                </h5>
                <p className="mb-5">
                  Our team of experienced professionals offers a range of
                  specialized services, including implementation assistance,
                  training, compliance reviews, and advisory support. We work
                  closely with your organization to understand its unique needs
                  and challenges, providing tailored solutions to ensure
                  seamless compliance with IFRS and optimize financial reporting
                  practices.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  What sets O.G.M. Consultants apart from other advisory firms?
                </h5>
                <p className="mb-5">
                  At O.G.M. Consultants, we distinguish ourselves through our
                  deep expertise in IFRS and our commitment to client
                  satisfaction. Our team comprises seasoned professionals with
                  extensive experience in financial reporting and accounting
                  standards, enabling us to deliver practical, results-oriented
                  solutions that drive value for your business. We prioritize
                  collaboration, transparency, and excellence in everything we
                  do, striving to be your trusted partner in achieving your
                  financial reporting objectives
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How can I get started with O.G.M. Consultants?
                </h5>
                <p>
                  Getting started with O.G.M. Consultants is easy! Simply reach
                  out to our team to schedule a consultation. We'll take the
                  time to understand your organization's needs and objectives,
                  and tailor our services to meet your specific requirements.
                  Whether you're looking for assistance with IFRS
                  implementation, training, compliance reviews, or advisory
                  support, we're here to help you navigate the complexities of
                  financial reporting and achieve success.
                </p>
                <br />
                <p />
                <p>
                  For expert guidance and support in navigating the complexities
                  of International Financial Reporting Standards, trust O.G.M.
                  Consultants as your dedicated advisory partner. Contact us
                  today to learn more about our services and how we can help
                  your organization thrive in today's global marketplace.
                </p>
                <p />
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/faq.jpg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Ends */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Advisory;
