import React from "react";

function Footer() {
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <div>
                  <a className="navbar-brand" href="#">
                    <img
                      src="assets/img/logo.jpg"
                      alt=""
                      width={185}
                      height={70}
                      style={{ borderRadius: "3px", marginBottom: "20px" }}
                    />
                  </a>
                </div>
                As a premier financial management firm in the GCC, we are
                renowned for our swift and reliable services. Our expertise
                ensures efficient ground-breaking solutions, setting new
                standards in financial excellence for our clients.
                <p />
                <form action>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <p className="mb-0">
                      IFZA Business Park, Dubai, United Arab Emirates.
                    </p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <p className="mb-0">
                      128 City Road, EC1V 2NX, London, United Kingdom.
                    </p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <p className="mb-0">
                      16192 Coastal Highway, Lewes, P.O. Box 19958 Delaware,
                      USA.
                    </p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2" />
                    <p className="mb-0">info@ogmholding.com</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2" />
                    <p className="mb-0">+971502923136</p>
                  </div>
                  <div className="d-flex mt-4">
                    <a
                      className="btn btn-primary btn-square me-2"
                      href="https://www.facebook.com/profile.php?id=100091747087457&mibextid=LQQJ4d"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f fw-normal" />
                    </a>
                    <a
                      className="btn btn-primary btn-square me-2"
                      href="https://www.linkedin.com/company/o-g-m-holding-co-ltd/"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin-in fw-normal" />
                    </a>
                    <a
                      className="btn btn-primary btn-square"
                      href="https://www.instagram.com/ogmconsultants_?igsh=OGo5ZjkxaGRoODQz&utm_source=qr"
                      target="_blank"
                    >
                      <i className="fab fa-instagram fw-normal" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <a className="text-light mb-2" href="About">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Who We Are
                    </a>
                    <a className="text-light mb-2" href="Office">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Our Offices
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Meet The Team
                    </a>
                    <a className="text-light mb-2" href="Blog">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Blog Posts
                    </a>
                    <a className="text-light" href="Contact-us">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Contact Us
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Services</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <a className="text-light mb-2" href="business-setup">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Business Setup
                    </a>
                    <a className="text-light mb-2" href="Account-taxation">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Accounting &amp; Taxation
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Statutory Compliance
                    </a>
                    <a className="text-light mb-2" href="Payroll">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      People and Payroll
                    </a>
                    <a className="text-light" href="Quote">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Request a Free Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "75px" }}
              >
                <p className="mb-0">
                  © 2024{" "}
                  <a className="text-white border-bottom" href="#">
                    OGM Holding
                  </a>
                  . All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
      {/* Back to Top */}
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i className="bi bi-arrow-up" />
      </a>
    </>
  );
}

export default Footer;
