import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function FinancialStatement() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Financial Statement Reporting
              </h1>
              <a href="index.html" className="h5 text-white">
                Statutory &amp; Complaince
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">
                Financial Statement Reporting
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">Expert FIRS Service</h1>
                </div>
                <p className="mb-4">
                  Welcome to O.G.M. Consultants, your trusted partner in
                  navigating the complex landscape of financial statement
                  reporting in accordance with International Financial Reporting
                  Standards (IFRSs). As experts in the field, we understand the
                  importance of accurate and transparent financial reporting for
                  businesses operating in today's global economy. Whether you're
                  a multinational corporation or a small-to-medium enterprise,
                  adhering to IFRSs is essential for ensuring credibility,
                  transparency, and compliance with regulatory requirements.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/financial-reporting.jpg"
                    alt="financial statement reporting"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Blog Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">Scope</h5>
              <h1 className="mb-0">What We Offer</h1>
            </div>
            <p className="text-center">
              At O.G.M. Consultants, we offer comprehensive services to assist
              you in preparing and presenting your financial statements in
              accordance with IFRSs. Our team of experienced professionals will
              work closely with you to:
            </p>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">IFRS Implementation</h4>
                  <p className="m-0">
                    We provide guidance and support in implementing IFRSs within
                    your organization, ensuring seamless integration and
                    compliance with the latest standards.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Financial Statement Preparation</h4>
                  <p className="m-0">
                    Our experts will prepare your financial statements in
                    accordance with IFRSs, including the balance sheet, income
                    statement, statement of changes in equity, and cash flow
                    statement.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Technical Accounting Advice</h4>
                  <p className="m-0">
                    We offer technical accounting advice on complex issues
                    related to IFRSs, helping you navigate intricate reporting
                    requirements and ensure accuracy in financial reporting.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Training and Workshops</h4>
                  <p className="m-0">
                    Our customized training programs and workshops are designed
                    to equip your team with the knowledge and skills necessary
                    to understand and apply IFRSs effectively.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">IFRS Compliance Review</h4>
                  <p className="m-0">
                    We conduct comprehensive reviews of your financial
                    statements to ensure compliance with IFRSs and identify any
                    areas for improvement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default FinancialStatement;
