import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function CompanyFormation() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ "margin-bottom": "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 mt-xs-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Cayman Company Formation
              </h1>
              <a href="Our-team" className="h5 text-white">
                Business Formation
              </a>
              <i className="far fa-circle text-white px-2" />
              <a href="Office" className="h5 text-white">
                Cayman Business Formation
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Cayman Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Offering Cayman Business Setup</h1>
                </div>
                <p className="mb-4">
                  Are you considering expanding your business into an
                  international financial hub? Look no further than the Cayman
                  Islands, a premier destination for offshore business. At O.G.M
                  Consultants, we specialize in Cayman Company Formation,
                  providing comprehensive solutions tailored to meet your unique
                  business needs.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "250px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/cayman-business-formation.jpg"
                    alt="cayman business formation"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cayman End */}
        {/* Reuired Docs Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Why Cayman
              </h5>
              <h1 className="mb-0">Benefits of Forming a Company in Cayman</h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Tax Advantages</h4>
                  <p className="m-0">
                    The Cayman Islands offer a tax-neutral environment, making
                    it an attractive destination for businesses seeking tax
                    efficiency and asset protection.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Financial Privacy</h4>
                  <p className="m-0">
                    Enjoy enhanced financial privacy with strict confidentiality
                    laws, ensuring the security of your business affairs.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Stability and Reputation</h4>
                  <p className="m-0">
                    Benefit from a politically stable jurisdiction with a strong
                    reputation for regulatory compliance and financial
                    integrity.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Global Accessibility</h4>
                  <p className="m-0">
                    Strategically located in the Caribbean, the Cayman Islands
                    provide easy access to international markets, making it an
                    ideal base for global business operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Reuired Docs End */}
        {/* Cost Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Legal Types of Companies in Cayman</h1>
                </div>
                <div className="row g-0 mb-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Exempted Company
                    </h5>
                    <p>
                      Suitable for businesses conducting activities outside the
                      Cayman Islands.
                    </p>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Limited Liability Company (LLC)
                    </h5>
                    <p>
                      Offers flexible structuring with separate legal
                      personality, allowing for various membership structures.
                    </p>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Segregated Portfolio Company (SPC)
                    </h5>
                    <p>
                      Ideal for investment funds, SPCs allow the segregation of
                      assets and liabilities between different portfolios.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5" style={{ "min-height": "400px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/legal-companies-in-cayman.jpg"
                    alt="legal companies in cayman"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cost End */}
        {/* Reuired Docs Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Requirements
              </h5>
              <h1 className="mb-0">
                Required Documents for Cayman Company Formation
              </h1>
            </div>
            <p className="text-center">
              Navigating the process is simplified with O.G.M Consultants. Here
              are the key documents you'll need:
            </p>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">
                    Memorandum &amp; Articles of Association
                  </h4>
                  <p className="m-0">
                    Outlines company details and internal regulations.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">
                    Directors' &amp; Shareholders' Information
                  </h4>
                  <p className="m-0">
                    Details of individuals involved in the company.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Registered Office Address</h4>
                  <p className="m-0">
                    An official address for receiving legal correspondence.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">
                    Know Your Customer (KYC) Documentation
                  </h4>
                  <p className="m-0">
                    AProof of identity and address for all directors and
                    shareholders.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Reuired Docs End */}
        {/* Cost Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Estimated Costs and Processing Time</h1>
                </div>
                <p className="mb-4">
                  Our transparent approach ensures you have a clear
                  understanding of the costs and timelines involved
                </p>
                <div className="row g-0 mb-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Government Fees
                    </h5>
                    <p>
                      These fees cover the registration and ongoing compliance
                      with the Cayman Islands Monetary Authority.
                    </p>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Professional Services
                    </h5>
                    <p>
                      O.G.M Consultants offers competitive packages, including
                      document preparation, filing, and ongoing compliance
                      support.
                    </p>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Processing Time
                    </h5>
                    <p>
                      Cayman Company Formation typically takes 4-6 weeks,
                      subject to regulatory approvals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5" style={{ "min-height": "500px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/cost-and-time.jpg"
                    alt="cost and time"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cost End */}
        {/* How OGM Help Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Why Choose O.G.M Consultants?</h1>
                </div>
                <p className="mb-4">
                  At O.G.M Consultants, we pride ourselves on delivering
                  personalized and efficient services to our clients. Our
                  experienced team is dedicated to ensuring your Cayman Company
                  Formation is seamless, compliant, and tailored to your
                  business objectives. Embark on your international business
                  journey with confidence. Contact O.G.M Consultants today, and
                  let us guide you through the process of establishing your
                  business presence in the Cayman Islands.
                </p>
                {/* <div
                  className="d-flex align-items-center mb-4 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0">+971502923136</h4>
                  </div>
                </div> */}
                <a
                  href="Quote"
                  className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  Request A Quote
                </a>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/best-cayman-company-formation.jpg"
                    alt="best-cayman-company-formation"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* How OGM Help End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default CompanyFormation;
