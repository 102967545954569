import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function AccountingTaxation() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Accounting &amp; Taxation
              </h1>
              <a href="index.html" className="h5 text-white">
                Home
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">
                Accounting &amp; Taxation
              </a>
            </div>
          </div>
        </div>

        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Features Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Why Choose Us
              </h5>
              <h1 className="mb-0">
                We Are Here to Grow Your Business Exponentially
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-lg-4">
                <div className="row g-5">
                  <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                    <div
                      className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <i className="fa fa-cubes text-white" />
                    </div>
                    <h4>Best In Industry</h4>
                    <p className="mb-0">
                      Magna sea eos sit dolor, ipsum amet lorem diam dolor eos
                      et diam dolor
                    </p>
                  </div>
                  <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                    <div
                      className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <i className="fa fa-award text-white" />
                    </div>
                    <h4>Award Winning</h4>
                    <p className="mb-0">
                      Magna sea eos sit dolor, ipsum amet lorem diam dolor eos
                      et diam dolor
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4  wow zoomIn"
                data-wow-delay="0.9s"
                style={{ minHeight: "350px" }}
              >
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.1s"
                    src="assets/img/feature.jpg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row g-5">
                  <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                    <div
                      className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <i className="fa fa-users-cog text-white" />
                    </div>
                    <h4>Professional Staff</h4>
                    <p className="mb-0">
                      Magna sea eos sit dolor, ipsum amet lorem diam dolor eos
                      et diam dolor
                    </p>
                  </div>
                  <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                    <div
                      className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <i className="fa fa-phone-alt text-white" />
                    </div>
                    <h4>24/7 Support</h4>
                    <p className="mb-0">
                      Magna sea eos sit dolor, ipsum amet lorem diam dolor eos
                      et diam dolor
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Features Start */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default AccountingTaxation;
