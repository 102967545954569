import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function Deligence() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>
            Due Diligence Services In Dubai | Due Diligence in Dubai
          </title>
          <meta
            name="description"
            content="We are the best due diligence companies in Dubai. Our team of experienced professionals is available to assist with all types of due diligence services. Commercial, financial, and accounting due diligence in Dubai. Get to know more about our services Contact us !"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Due Deligence
              </h1>
              <a href="index.html" className="h5 text-white">
                Statutory &amp; Complaince
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Due Deligence</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">Due Deligence</h1>
                </div>
                <p className="mb-4">
                  Due diligence is a comprehensive investigation and analysis
                  process conducted before entering into a business transaction.
                  It involves examining all relevant aspects of a company, such
                  as its financial records, legal obligations, operational
                  practices, and market position, to assess risks and
                  opportunities accurately.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/due_diligence1.jpg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Blog Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div className="row">
              <div className="col-md-4">
                <ul>
                  <li>Thoroughly assess potential risks.</li>
                  <li>Ensure compliance with regulations.</li>
                  <li>Verify financial data and documents.</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul>
                  <li>Investigate company backgrounds.</li>
                  <li>Identify potential legal issues.</li>
                  <li>Assess operational efficiency.</li>
                  <li>Evaluate market positioning.</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul>
                  <li>Confirm asset ownership.</li>
                  <li>Analyze industry trends.</li>
                  <li>
                    Provide comprehensive reports for informed decision-making.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Deligence;
