import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function BeneficialOwner() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Ultimate Beneficial Owner
              </h1>
              <a href="index.html" className="h5 text-white">
                Statutory &amp; Complaince
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">
                Ultimate Beneficial Owner
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">Ultimate Beneficial Owner</h1>
                </div>
                <p className="mb-4">
                  The Ultimate Beneficiary Owner (UBO) is the individual who
                  ultimately owns or controls a company, playing a crucial role
                  in transparency and compliance.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/Ultimate_beneficial.jpg"
                    alt="'ultimate beneficial owner','ubo'"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Blog Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div className="section-title position-relative pb-3 mb-5 d-flex flex-column align-items-center justify-content-center text-center">
              <h1 className="mb-0">Why is UBO Compliance Required?</h1>
            </div>
            <div className="row">
              <div className="col-md-6">
                <ul>
                  <li>
                    UBO information is essential for regulatory compliance and
                    due diligence processes.
                  </li>
                  <li>
                    Knowing the UBO provides insight into potential risks and
                    ensures responsible business dealings.
                  </li>
                  <li>
                    Properly documenting UBOs enhances corporate governance and
                    trust in business relationships.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>
                    Compliance with UBO disclosure requirements is necessary in
                    many jurisdictions to prevent illicit activities.
                  </li>
                  <li>
                    Understanding UBOs facilitates better risk management and
                    strategic decision-making.
                  </li>
                  <li>
                    Transparency regarding UBOs fosters integrity and
                    accountability within the corporate sector.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default BeneficialOwner;
