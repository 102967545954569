import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function SupplyChain() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Supply Chain
              </h1>
              <a href="index.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Supply Chain</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* About Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">Our Approach</h1>
                </div>
                <p className="mb-4">
                  At O.G.M. Consultants, we understand that an efficient and
                  well-optimized supply chain is the backbone of successful
                  businesses. In today's dynamic and competitive business
                  environment, organizations need to continuously adapt and
                  enhance their supply chain processes to stay ahead. That's
                  where O.G.M. Consultants comes in – your dedicated partner in
                  achieving supply chain excellence.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/our_approach.jpg"
                    alt="supply chain"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            <div className="row g-5 py-5">
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <h4 className="mb-3">
                    Tailored Solutions for Your Unique Challenges
                  </h4>
                  <p className="m-0">
                    Every business is unique, and so are its supply chain
                    challenges. O.G.M. Consultants takes a personalized approach
                    to understand your specific needs and challenges. Our team
                    of experienced consultants works closely with you to design
                    customized solutions that align with your business goals and
                    objectives.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <h4 className="mb-3">End-to-End Supply Chain Expertise</h4>
                  <p className="m-0">
                    From procurement to distribution, our consultants bring
                    comprehensive expertise across the entire supply chain
                    spectrum. Whether you are looking to streamline your
                    procurement processes, optimize inventory management, or
                    enhance distribution networks, we have the knowledge and
                    experience to guide you through each step.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <h4 className="mb-3">Data-Driven Decision Making</h4>
                  <p className="m-0">
                    In the era of big data, leveraging analytics is crucial for
                    making informed decisions. O.G.M. Consultants integrates
                    data-driven insights into our consulting approach, helping
                    you make strategic decisions that improve efficiency, reduce
                    costs, and enhance overall supply chain performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Dubai Business Formation */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                What the Service Cover
              </h5>
              <h1 className="mb-0">Our Supply Chain Service Includes</h1>
            </div>
            <div className="row g-5">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Supply Chain Strategy</h4>
                  <p className="m-0">
                    Developing a robust supply chain strategy is the first step
                    towards success. Our consultants collaborate with your team
                    to define clear objectives, identify opportunities for
                    improvement, and design a strategic roadmap that aligns with
                    your business goals.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Procurement Optimization</h4>
                  <p className="m-0">
                    Efficient procurement processes are essential for cost
                    control and supplier relationship management. O.G.M.
                    Consultants works with you to optimize procurement
                    strategies, negotiate favorable terms, and enhance supplier
                    collaboration to ensure a resilient and cost-effective
                    supply chain.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Inventory Management</h4>
                  <p className="m-0">
                    Balancing inventory levels to meet customer demand while
                    minimizing carrying costs is a delicate art. Our experts
                    help you implement effective inventory management practices,
                    utilizing advanced technologies and analytics to achieve
                    optimal stock levels and reduce excess holding costs.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Distribution Network Optimization</h4>
                  <p className="m-0">
                    Creating an agile and responsive distribution network is
                    critical in today's fast-paced business environment. O.G.M.
                    Consultants assists you in designing and optimizing your
                    distribution network, ensuring timely deliveries, reducing
                    transportation costs, and enhancing overall customer
                    satisfaction.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Technology Integration</h4>
                  <p className="m-0">
                    Stay ahead of the curve by leveraging cutting-edge
                    technologies. O.G.M. Consultants guides you in adopting and
                    integrating the latest technologies, such as blockchain,
                    IoT, and AI, to enhance visibility, traceability, and
                    overall supply chain performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dubai Business Formation Ends*/}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/supply-chain-benefits.jpg"
                    alt="supply chain benefits"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h1 className="mb-0">Benefits</h1>
                </div>
                <ul>
                  <li>
                    <b>Proven Track Record: </b>Our consultants have a proven
                    track record of successfully helping businesses optimize
                    their supply chain processes and achieve sustainable growth.
                  </li>
                  <li>
                    <b>Collaborative Approach: </b>We believe in building strong
                    partnerships with our clients, working collaboratively to
                    address challenges and seize opportunities.
                  </li>
                  <li>
                    <b>Innovation and Technology: </b>Embrace the future with
                    our forward-thinking approach, incorporating innovative
                    technologies to future-proof your supply chain.
                  </li>
                  <li>
                    <b>Global Perspective: </b>With a global perspective and
                    local expertise, we cater to diverse industries and markets,
                    ensuring our solutions are tailored to your specific needs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row g-5 py-5">
              <p>
                Ready to transform your supply chain? Contact O.G.M. Consultants
                today, and let's embark on the journey towards supply chain
                excellence together.
              </p>
            </div>
          </div>
        </div>
        {/* FAQ Starts */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h1 className="mb-0">FAQs </h1>
                </div>
                <h5 className="fw-bold text-primary text-uppercase">
                  Why is supply chain consulting important for my business?
                </h5>
                <p className="mb-5">
                  A well-optimized supply chain can lead to cost savings,
                  improved efficiency, and enhanced customer satisfaction. Our
                  consulting services are designed to help businesses navigate
                  complex supply chain challenges and achieve sustainable
                  success.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How long does it take to see results from supply chain
                  consulting?
                </h5>
                <p className="mb-5">
                  The timeline for results varies based on the complexity of
                  your supply chain and the specific challenges you're facing.
                  Our consultants work efficiently to deliver measurable
                  improvements within a reasonable timeframe.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  Is supply chain consulting only for large corporations?
                </h5>
                <p className="mb-5">
                  No, our services cater to businesses of all sizes. Whether
                  you're a small startup or a large enterprise, we tailor our
                  solutions to meet your unique needs and budget.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How can technology integration benefit my supply chain?
                </h5>
                <p className="mb-5">
                  Technology integration enhances visibility, efficiency, and
                  decision-making. From real-time tracking to predictive
                  analytics, integrating technology into your supply chain
                  processes can provide a competitive edge in today's fast-paced
                  business environment.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/faq.jpg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Ends */}
        {/* Footer Start */}

        {/* Footer End */}

        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default SupplyChain;
