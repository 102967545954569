import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function BookKeeping() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>Bookkeeping Services in Dubai | OGM Consultants </title>
          <meta
            name="description"
            content="OGM Consultant offers a wide range of bookkeeping services for businesses in Dubai, including the preparation of chart of accounts and the formation of ledgers.Contact us today!"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Bookkeeping
              </h1>
              <a href="business-setup.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Bookkeeping</a>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Bookkeeping Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Bookkeeping
                  </h5>
                  <h1 className="mb-0">
                    We Provide Accurate &amp; Timely Bookkeeping Service
                  </h1>
                </div>
                <p className="mb-4">
                  With our Remote Bookkeeping services, we ensure that distance
                  is no barrier to efficient financial management. Experience
                  the convenience of accessing your financial records and
                  reports from anywhere in the world, while our skilled
                  professionals handle the intricacies of your accounts.
                  <br />
                  For those who prefer a hands-on approach, our In-house
                  Bookkeeping services provide a personalized touch to your
                  financial management. Our team can work directly from your
                  premises, ensuring a direct and immediate understanding of
                  your business dynamics.
                  <br />
                  At O.G.M Consultants, we are proficient in a wide range of
                  accounting software, including QuickBooks, Zoho Books, Tally,
                  Xero, Sage Excel and more.
                  <br />
                  Whether you have a preference for a specific software or need
                  assistance in choosing the right one for your business, we
                  have the expertise to guide you through the process.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/bookkeeping.jpg"
                    alt="bookkeeping"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bookeeping Start */}
        {/* Service Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Fee Structure
              </h5>
              <h1 className="mb-0">
                Remote Bookkeeping Service Fees in US Dollars (USD).
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-md-1" />
              <div
                className="col-lg-10 col-md-10 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="table-container">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Bookkeeping 1st Category</th>
                        <th>Bookkeeping 2nd Category</th>
                        <th>Bookkeeping 3rd Category</th>
                        <th>Bookkeeping 4th Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1- 100 transactions/month</td>
                        <td>101-250 transactions/month</td>
                        <td>251-400 transactions/month</td>
                        <td>&gt; 400 transactions/month</td>
                      </tr>
                      <tr>
                        <td>$ 180</td>
                        <td>$ 300</td>
                        <td>$ 499</td>
                        <td>Contact Us</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
        {/* Enquiry Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Have an Enquiry?
              </h5>
              <h1 className="mb-0">
                Do Not Hesitate To Contact Us For Your Free Consultation &amp;
                Quotations
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-md-3" />
              <div
                className=" col-md-6 col-md-offset-3 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <form action="https://formspree.io/f/xjvnjnea" method="post">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="f_name"
                        placeholder="First Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="s_name"
                        placeholder="Last Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        className="form-control border-0 bg-light px-4"
                        name="Email ID"
                        placeholder="Email ID"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        name="c_num"
                        placeholder="Contact Number"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control border-0 bg-light px-4 py-3"
                        rows={4}
                        name="Message"
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-3" />
            </div>
          </div>
        </div>
        {/* Enquiry End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default BookKeeping;
