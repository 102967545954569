import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function CorporateGov() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Corporate Governance
              </h1>
              <a href="index.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Corporate Governance</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* About Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">Insight</h1>
                </div>
                <p className="mb-4">
                  At O.G.M. Consultants, we recognize the critical importance of
                  strong corporate governance practices in fostering
                  transparency, accountability, and sustainability within
                  organizations. Our Corporate Governance Solutions are designed
                  to help businesses of all sizes navigate complex regulatory
                  landscapes, mitigate risks, and maximize long-term value
                  creation.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/corporate-governance.jpg"
                    alt="corporate"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            <div className="row g-5 mt-5">
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/our-approach.jpg"
                    alt="best corporate governance"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Our Approach</h1>
                </div>
                <p className="mb-4">
                  We believe that effective corporate governance is more than
                  just compliance—it's about creating a culture of integrity and
                  ethical behavior throughout an organization. Our approach to
                  corporate governance is holistic and tailored to meet the
                  unique needs and objectives of each client. We work closely
                  with boards of directors, executives, and stakeholders to
                  implement best practices and enhance governance structures.
                </p>
              </div>
            </div>
            <div className="row g-5 py-5">
              <div
                className="section-title text-center position-relative pb-3 my-5 mx-auto"
                style={{ maxWidth: "600px" }}
              >
                <h5 className="fw-bold text-primary text-uppercase" />
                <h1 className="mb-0">
                  Services We Offer In Corporate Governance
                </h1>
                <div>
                  <img
                    className="position-absolute rounded wow zoomIn services"
                    data-wow-delay="0.9s"
                    src="assets/img/services_corporate.jpg"
                    alt="audit and audit support"
                  />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-puzzle text-white" />
                  </div>
                  <h4 className="mb-3">Governance Framework Development</h4>
                  <p className="m-0">
                    We assist organizations in establishing robust governance
                    frameworks tailored to their specific industry, size, and
                    complexity. From developing corporate governance policies
                    and procedures to designing board structures and committees,
                    we provide comprehensive solutions to strengthen governance
                    practices.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-clipboard-check text-white" />
                  </div>
                  <h4 className="mb-3">Board Effectiveness Evaluation</h4>
                  <p className="m-0">
                    Our consultants conduct thorough assessments of board
                    performance and effectiveness. Through interviews, surveys,
                    and benchmarking against industry standards, we identify
                    areas for improvement and recommend strategies to enhance
                    board dynamics, decision-making processes, and overall
                    effectiveness.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-shield-check text-white" />
                  </div>
                  <h4 className="mb-3">Risk Management and Compliance</h4>
                  <p className="m-0">
                    We help organizations identify, assess, and mitigate risks
                    related to regulatory compliance, financial integrity, and
                    reputational damage. Our experts develop risk management
                    frameworks, implement internal controls, and provide ongoing
                    monitoring to ensure compliance with laws, regulations, and
                    ethical standards.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-lightbulb text-white" />
                  </div>
                  <h4 className="mb-3">Ethics and Corporate Responsibility</h4>
                  <p className="m-0">
                    Ethical conduct and corporate responsibility are fundamental
                    pillars of effective governance. We work with organizations
                    to establish ethical codes of conduct, whistleblower
                    policies, and sustainability initiatives that align with
                    their values and promote responsible business practices.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-chat-dots text-white" />
                  </div>
                  <h4 className="mb-3">Stakeholder Engagement</h4>
                  <p className="m-0">
                    Engaging with stakeholders is essential for building trust
                    and maintaining transparency. We facilitate meaningful
                    dialogues between organizations and their stakeholders,
                    including shareholders, employees, customers, and
                    communities, to foster trust, enhance communication, and
                    align interests.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">Why OGM?</h5>
              <h1 className="mb-0">
                Choose OGMBC Consultants for Unmatched Governance Solutions
              </h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Expertise:</h4>
                  <p className="m-0">
                    Our team comprises seasoned professionals with extensive
                    experience in corporate governance, compliance, and risk
                    management across diverse industries.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Customized Solutions:</h4>
                  <p className="m-0">
                    We understand that every organization is unique. Our
                    consultants tailor solutions to address the specific
                    governance challenges and goals of each client.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Results-Driven:</h4>
                  <p className="m-0">
                    We are committed to delivering tangible results. Our focus
                    is on implementing practical solutions that drive long-term
                    value creation and sustainability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Starts */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h1 className="mb-0">FAQs </h1>
                </div>
                <h5 className="fw-bold text-primary text-uppercase">
                  What is corporate governance, and why is it important?
                </h5>
                <p className="mb-5">
                  Corporate governance refers to the system of rules, practices,
                  and processes by which a company is directed and controlled.
                  It is important because it ensures transparency,
                  accountability, and fairness, which are essential for building
                  trust among stakeholders and sustaining long-term business
                  success.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How can O.G.M. Consultants help improve our organization's
                  corporate governance?
                </h5>
                <p className="mb-5">
                  Our consultants provide a range of services, including
                  governance framework development, board effectiveness
                  evaluation, risk management, ethics and corporate
                  responsibility, and stakeholder engagement. We work
                  collaboratively with your organization to identify areas for
                  improvement and implement tailored solutions to enhance
                  governance practices.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  What are the benefits of having strong corporate governance
                  practices?
                </h5>
                <p className="mb-5">
                  Strong corporate governance practices can lead to improved
                  decision-making, enhanced risk management, better financial
                  performance, increased investor confidence, and greater
                  stakeholder trust. Additionally, they can help mitigate legal
                  and reputational risks and ensure compliance with regulatory
                  requirements.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How often should our organization conduct board effectiveness
                  evaluations?
                </h5>
                <p className="mb-5">
                  Board effectiveness evaluations should be conducted regularly
                  to ensure that the board is functioning efficiently and
                  fulfilling its responsibilities effectively. The frequency may
                  vary depending on factors such as the organization's size,
                  industry, and regulatory requirements. Typically, evaluations
                  are conducted annually or biennially. At O.G.M. Consultants,
                  we are committed to helping organizations build resilient,
                  ethical, and high-performing governance structures. Contact us
                  today to learn more about how our Corporate Governance
                  Solutions can benefit your organization.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/faq.jpg"
                    alt="faq"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Ends */}
        {/* About End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default CorporateGov;
