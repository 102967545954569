import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function Team() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ "margin-bottom": "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Our Team</h1>
              <a href="Who-we-are" className="h5 text-white">
                Who We Are
              </a>
              <i className="far fa-circle text-white px-2" />
              <a href="office" className="h5 text-white">
                Our Offices
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* About Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Here for you
                  </h5>
                  <h1 className="mb-0">
                    Expert Team for Unparalleled Accounting Services, Ensuring
                    Precision &amp; Client Satisfaction.
                  </h1>
                </div>
                <p className="mb-4">
                  With a team of highly skilled and experienced professionals,
                  our OGM Consultants ensures unparalleled expertise. Our
                  dedicated staff members bring in-depth knowledge and
                  commitment, guaranteeing top-notch financial services. Whether
                  it's accounting, taxation, financial advisory or global
                  business setup, our team excels in delivering precise
                  solutions tailored to meet the diverse needs of our clients,
                  fostering trust and success.
                </p>
                <div className="row g-0 mb-3">
                  <h5 className="fw-bold text-primary text-uppercase mt-3">
                    Our Values
                  </h5>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Expertise
                    </h5>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Dedication
                    </h5>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Client Centricity
                    </h5>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Accountability
                    </h5>
                  </div>
                </div>
                <a
                  href="quote.html"
                  className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  Request A Quote
                </a>
              </div>
              <div className="col-lg-5" style={{ "min-height": "500px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/professional-team.jpg"
                    alt="professional-team"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Team Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Team Members
              </h5>
              <h1 className="mb-0">
                Professional Staff Ready to Help Your Business
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div className="team-item bg-light rounded overflow-hidden">
                  <div className="team-img position-relative overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="assets/img/team-1.jpg"
                      alt
                    />
                    <div className="team-social">
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-twitter fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-facebook-f fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-instagram fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-linkedin-in fw-normal" />
                      </a>
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <h4 className="text-primary">Full Name</h4>
                    <p className="text-uppercase m-0">Designation</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div className="team-item bg-light rounded overflow-hidden">
                  <div className="team-img position-relative overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="assets/img/team-2.jpg"
                      alt
                    />
                    <div className="team-social">
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-twitter fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-facebook-f fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-instagram fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-linkedin-in fw-normal" />
                      </a>
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <h4 className="text-primary">Full Name</h4>
                    <p className="text-uppercase m-0">Designation</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                  <div className="team-img position-relative overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="assets/img/team-3.jpg"
                      alt
                    />
                    <div className="team-social">
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-twitter fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-facebook-f fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-instagram fw-normal" />
                      </a>
                      <a
                        className="btn btn-lg btn-primary btn-lg-square rounded"
                        href
                      >
                        <i className="fab fa-linkedin-in fw-normal" />
                      </a>
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <h4 className="text-primary">Full Name</h4>
                    <p className="text-uppercase m-0">Designation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Team End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Team;
