import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function UkCompany() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>
            Company formation in UK | UK Company Formation Service in Dubai | UK
            Company Setup & Registration in Dubai{" "}
          </title>
          <meta
            name="description"
            content="Are you interested in starting a company formation in UK from Dubai? OGM Consultant provides you with a service that is reliable for your business. Contact us for a free consultation now!"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                UK Company Formation
              </h1>
              <a href="Business-setup" className="h5 text-white">
                Business Setup
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">United Kingdom</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Why UK Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">
                    Offering UK Business Setup For Residents Or Non Residents
                  </h1>
                </div>
                <p className="mb-4">
                  Whether you are currently residing in the UK or Non- UK
                  resident you can set up your own company in the United
                  Kingdom. Congratulations on taking the first step towards
                  entrepreneurial success! At OGM Business Consultants, we are
                  here to guide you through the seamless process of UK company
                  formation, ensuring a smooth and efficient experience for you.
                </p>
                <div
                  className="d-flex align-items-center mb-4 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  {/* <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0">+012 345 6789</h4>
                  </div> */}
                </div>
                <a
                  href="Quote"
                  className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  Request A Quote
                </a>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/uk-company-formation.jpg"
                    alt="uk company formation"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Why UK End */}
        {/* About Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/start-company-in-uk.jpg"
                    alt="start-company-in-uk"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">
                    Why Choose the UK for Company Formation?
                  </h1>
                </div>
                <p className="mb-4">
                  The United Kingdom is a global business hub with a robust and
                  well-regulated business environment. Setting up your company
                  here offers numerous advantages, including access to a highly
                  skilled workforce, a stable political climate, and a vast
                  network of international trade opportunities.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Reuired Docs Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title pb-3 mb-5 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Requirements
              </h5>
              <h1 className="mb-0">
                Required Documents for UK Company Formation
              </h1>
            </div>
            <p className="text-center">
              To get your business up and running, you'll need to gather a few
              essential documents. Don't worry – we're here to simplify the
              process for you. The key documents include:
            </p>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Memorandum of Association</h4>
                  <p className="m-0">
                    Outlines the company's name, location, and objectives.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Articles of Association</h4>
                  <p className="m-0">
                    Defines the internal rules governing the company's
                    operations.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Form IN01</h4>
                  <p className="m-0">
                    Provides details about company directors, shareholders, and
                    the registered office address.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Confirmation Statement</h4>
                  <p className="m-0">
                    An annual filing confirming key company details.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Director's Service Address</h4>
                  <p className="m-0">
                    Specifies the official address for company directors.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Registered Office Address</h4>
                  <p className="m-0">
                    The official address for receiving legal correspondence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Reuired Docs End */}
        {/* Cost Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">
                    Estimated Costs of UK Company Formation
                  </h1>
                </div>
                <p className="mb-4">
                  We understand that budgeting is crucial, especially when
                  starting a new venture. Here's a breakdown of the estimated
                  costs involved in forming your company
                </p>
                <div className="row g-0 mb-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Government Filing Fees
                    </h5>
                    <p>
                      These fees cover the processing of your company formation
                      documents. Costs may vary depending on the type of
                      company.
                    </p>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Registered Office Address
                    </h5>
                    <p>
                      The cost of securing a registered office address, which is
                      a legal requirement for your company.
                    </p>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Professional Services
                    </h5>
                    <p>
                      Engaging professional services, such as legal or
                      accounting advice, can contribute to a smoother company
                      formation process.
                    </p>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Company Formation Packages
                    </h5>
                    <p>
                      Consider opting for our comprehensive company formation
                      packages, which can include various services like document
                      preparation, filing, and ongoing support.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5" style={{ "min-height": "500px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/uk-company-formation-cost.jpg"
                    alt="uk company formation cost"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cost End */}
        {/* How OGMBC Help Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-5">
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/company-formation-help.jpg"
                    alt="company formation help"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7" style={{ "min-height": "500px" }}>
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">How We Can Be of Help</h1>
                </div>
                <p className="mb-4">
                  Our team at OGMBC Consultants is dedicated to ensuring your
                  company formation journey is as straightforward as possible.
                  We offer tailored solutions to meet your specific needs,
                  providing expert guidance and support at every step.
                </p>
                <div className="row g-0 mb-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      This service usually include:
                    </h5>
                    <ul>
                      <li>Document preparation and filing</li>
                      <li>
                        Assistance with choosing the right company structure
                      </li>
                      <li>Ongoing compliance support</li>
                      <li>
                        Registered office and director's service address
                        services
                      </li>
                      <li>
                        Access to professional advice from our network of
                        experts
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  Embark on your entrepreneurial journey with confidence.
                  Contact OGMBC Consultants today, and let us help you turn your
                  business dreams into reality.
                </p>
                {/* <div
                  className="d-flex align-items-center mb-4 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0">+971502923136</h4>
                  </div>
                </div> */}
                <a
                  href="Book-keeping"
                  className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  Request A Quote
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* How OGMBC Help End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default UkCompany;
