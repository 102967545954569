import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function EcommerceSubstance() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Economic Substance Regulation
              </h1>
              <a href="index.html" className="h5 text-white">
                Statutory &amp; Complaince
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">
                Economic Substance Regulation
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">UAE ESR</h1>
                </div>
                <p className="mb-4">
                  In alignment with international standards, the United Arab
                  Emirates (UAE) has implemented Economic Substance Regulation
                  (ESR) to ensure transparency and compliance among businesses
                  operating within its jurisdiction. ESR requires certain
                  entities to demonstrate economic substance in the UAE relative
                  to the activities they undertake.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/economic-substance-regulation.jpg"
                    alt="'economic substance regulation', 'ESR'"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Blog Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">Overview</h5>
              <h1 className="mb-0">
                What is Economic Substance Regulation (ESR)?
              </h1>
            </div>
            <p className="text-center">
              ESR mandates that entities conducting relevant activities within
              the UAE must maintain sufficient economic substance by conducting
              core income-generating activities locally. These activities
              include banking, insurance, fund management, lease financing,
              headquarters, shipping, distribution, and service centers.
            </p>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <h1 className="mb-0">
                Key Features of UAE Economic Substance Regulation
              </h1>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Scope of Applicability</h4>
                  <p className="m-0">
                    ESR applies to a wide range of entities, including free zone
                    and onshore companies, branches, and partnerships, engaged
                    in specified relevant activities.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Reporting Obligations</h4>
                  <p className="m-0">
                    Covered entities are required to submit an annual Economic
                    Substance Notification (ESN) to the relevant regulatory
                    authority, disclosing information regarding their activities
                    and compliance with ESR requirements.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Compliance and Penalties</h4>
                  <p className="m-0">
                    Non-compliance with ESR may result in penalties, including
                    fines, suspension, or revocation of licenses, highlighting
                    the importance of adhering to regulatory obligations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default EcommerceSubstance;
