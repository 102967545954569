import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function AccountOpening() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Bank Account Opening
              </h1>
              <a href="index.html" className="h5 text-white">
                Support
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Bank Account Opening</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div className="section-title position-relative pb-3 mb-5 d-flex flex-column align-items-center justify-content-center text-center">
              <h1 className="mb-0">Bank Account Opening</h1>
            </div>
            <div className="row g-5 py-5">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <h4 className="mb-3">UAE Bank Account Opening</h4>
                  <p className="m-0">
                    Opening a bank account in the UAE is a streamlined process,
                    typically requiring standard documents such as a passport
                    and proof of address. With a range of account options
                    available and efficient service, establishing banking
                    relationships in the UAE provides access to a robust
                    financial ecosystem in one of the world's leading business
                    hubs.{" "}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <h4 className="mb-3">USA Bank Account Opening</h4>
                  <p className="m-0" />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <h4 className="mb-3">UK Bank Account Opening</h4>
                  <p className="m-0" />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <h4 className="mb-3">EU Bank Account Opening</h4>
                  <p className="m-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default AccountOpening;
