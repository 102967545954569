import { useParams, useSearchParams } from "react-router-dom";
import React, { Fragment } from "react";
import { BLOG_LIST } from "../blog-list";
import styles from "./BlogPost.module.css";

const blogPosts = BLOG_LIST;

const BlogPost = () => {
  // const { id } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const post = blogPosts.find((post) => post.id === id);
  console.log("Blog Id", useParams());

  if (!post) {
    return (
      <Fragment>
        <h2>Blog Post Not Found</h2>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <div>
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "40px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Blog</h1>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Post Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase"></h5>
                  <div
                    className={styles.detailBlogTitle}
                    dangerouslySetInnerHTML={{ __html: post.title }}
                  />
                  <div
                    className={styles.detailBlogContent}
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlogPost;
