import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function TaxConsultancy() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>
            Tax Consultants in Dubai | Tax Consulting Services in UAE{" "}
          </title>
          <meta
            name="description"
            content="Searching for Best Tax Consultants in Dubai ? OGM Consulatant as one of the top tax consultants and advisors in the UAE, offer dependable tax preparation services and tax planning in Dubai. To know more about us Contact us +971 50 986 0136"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Tax Consultancy
              </h1>
              <a href="index.html" className="h5 text-white">
                Accounting &amp; Taxation
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Tax Consultancy</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Dubai Business Formation */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h1 className="mb-0">Categories</h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <h4 className="mb-3">UAE VAT &amp; CT Services</h4>
                  <ul>
                    <li>VAT Registration</li>
                    <li>VAT return filing</li>
                    <li>VAT refund</li>
                    <li>VAT reconsideration</li>
                    <li>VAT De-registration</li>
                    <li>Corporate Tax Registration</li>
                    <li>Corporate Tax Return filing</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <h4 className="mb-3">UK VAT &amp; Services</h4>
                  <ul>
                    <li>VAT Registration</li>
                    <li>Corporate Tax</li>
                    <li>CIS Return Filing</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <h4 className="mb-3">USA EIN Services</h4>
                  <ul>
                    <li>EIN Registration</li>
                    <li>Tax Return filing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dubai Business Formation Ends*/}
        {/* FAQ Starts */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h1 className="mb-0">FAQs </h1>
                </div>
                <h5 className="fw-bold text-primary text-uppercase">
                  What is VAT, and who needs to register for it in the UAE?
                </h5>
                <p className="mb-5">
                  VAT (Value Added Tax) is a consumption tax. In the UAE,
                  businesses meeting certain criteria are required to register
                  for VAT. This includes businesses with annual taxable supplies
                  exceeding the mandatory registration threshold.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How often do I need to file VAT returns in the UAE?
                </h5>
                <p className="mb-5">
                  VAT returns in the UAE are typically filed quarterly. The
                  exact frequency may depend on the nature and size of your
                  business.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  What is an EIN, and why do I need one in the United States?
                </h5>
                <p className="mb-5">
                  An Employer Identification Number (EIN) is a unique identifier
                  for businesses. It is required for various purposes, including
                  opening a business bank account, hiring employees, and filing
                  taxes.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/about.jpg"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Ends */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default TaxConsultancy;
