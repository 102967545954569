import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import Footer from "../components/Footer/Footer";

function Support() {
  return (
    <Fragment>
      <div>
        <Navmenu />

        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                AML Support
              </h1>
              <a href="index.html" className="h5 text-white">
                Statutory &amp; Complaince
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">AML Support</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">AML Support</h1>
                </div>
                <p className="mb-4">
                  Anti Money Laundering (AML) refers to measures taken to detect
                  and prevent the illegal process of concealing the origins of
                  money obtained through criminal activities. Implementing a
                  robust AML policy and procedures is essential for businesses
                  to mitigate risks and comply with regulatory requirements.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/anti-money-laundering.jpg"
                    alt="anti money laundering"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Blog Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div className="section-title position-relative pb-3 mb-5 d-flex flex-column align-items-center justify-content-center text-center">
              <h1 className="mb-0">What We Offer</h1>
            </div>
            <div className="row g-5 py-5">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-gear-wide-connected text-white" />
                  </div>
                  <h4 className="mb-3">Setting AML Policy</h4>
                  <p className="m-0">
                    AML policies outline guidelines and protocols for
                    identifying and reporting suspicious activities, ensuring
                    adherence to legal standards.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-braces text-white" />
                  </div>
                  <h4 className="mb-3">
                    Helping business to Set Effective AML Procedures
                  </h4>
                  <p className="m-0">
                    Effective AML procedures involve thorough customer due
                    diligence, including verifying identities and monitoring
                    transactions for irregularities.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-journal-text text-white" />
                  </div>
                  <h4 className="mb-3">Business Due Diligence</h4>
                  <p className="m-0">
                    Investigate company backgrounds, identify potential legal
                    issues and assess operational efficiency.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-person text-white" />
                  </div>
                  <h4 className="mb-3">Training staff</h4>
                  <p className="m-0">
                    Regular staff training on AML practices is vital to
                    maintaining vigilance and adherence to established
                    protocols.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <h4 className="mb-3">Review and update of AML polices</h4>
                  <p className="m-0">
                    Continuous review and updating of AML policies and
                    procedures are necessary to adapt to evolving regulatory
                    landscapes and emerging threats.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}

        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Support;
