import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function UaeCompany() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>UAE Company Formation Services | OGM Consultant </title>
          <meta
            name="description"
            content="Searching UAE Company Formative Services? OGM Holding offer complete assistance in setting up your company with ease. From choosing the appropriate legal structure to navigating the registration process. To know more about us Contact us on +971 50 986 0136"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                UAE Company Formation
              </h1>
              <a href="index.html" className="h5 text-white">
                Business Setup
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">United Arab Emirate</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* About Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGMBC</h5>
                  <h1 className="mb-0">
                    Your Trusted Partner in UAE Company Formation
                  </h1>
                </div>
                <p className="mb-4">
                  At OGM Business Consultants, we understand that navigating the
                  complex landscape of company formation in the United Arab
                  Emirates (UAE) requires expertise and precision. With years of
                  experience and a commitment to excellence, we offer
                  comprehensive solutions to guide you through the process of
                  establishing your business in the UAE. Whether you're a
                  startup or an established enterprise, our team is dedicated to
                  providing tailored services to meet your specific needs.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/uae-company-formation.jpg"
                    alt="uae company formation"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Service Start */}
        <div className="container wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "700px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">We Differ</h5>
              <h1 className="mb-0">
                Why OGMBC is your Best Option for UAE Company Formation
              </h1>
            </div>
            <div className="row g-4">
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <h4 className="mb-3">Expertise</h4>
                  <p className="m-0">
                    Our team of seasoned professionals possesses in-depth
                    knowledge of the UAE business environment, ensuring that
                    your company formation journey is seamless and compliant
                    with local regulations. We stay abreast of the latest
                    developments in UAE corporate law to provide you with
                    accurate and up-to-date advice.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <h4 className="mb-3">Tailored Solutions</h4>
                  <p className="m-0">
                    We recognize that every business is unique. Our consultants
                    work closely with you to understand your objectives and
                    design a customized company formation strategy. From
                    choosing the right legal structure to assisting with
                    documentation, we offer end-to-end solutions that align with
                    your business goals.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <h4 className="mb-3">Proactive Support</h4>
                  <p className="m-0">
                    At OGM Business Consultants Consultants, we pride ourselves
                    on providing proactive support throughout the entire company
                    formation process. Our team is readily available to address
                    your queries, offer guidance, and ensure that your business
                    is set up efficiently and effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service End */}
        {/* Dubai Business Formation */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "650px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Where We cover
              </h5>
              <h1 className="mb-0">
                UAE Company Formation Services
                <br /> Main Juridictions
              </h1>
            </div>
            <div className="row g-5">
              {/* Mainland */}
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Dubai Mainland</h1>
                </div>
                <p className="mb-4">
                  Ideal for businesses targeting the local market. <br />
                  Full access to UAE and international markets. <br />
                  Various legal structures available, including LLC, Sole
                  Proprietorship, and more.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/mainland.jpg"
                    alt="mainland business formation"
                    style={{ "object-fit": "fill" }}
                  />
                </div>
              </div>
              {/* Freezone */}
              <div className="col-lg-5 py-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/freezone.jpg"
                    alt="freezon business formation"
                    style={{ "object-fit": "fill" }}
                  />
                </div>
              </div>
              <div className="col-lg-7 py-5">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Free Zones</h1>
                </div>
                <p className="mb-4">
                  Offers 100% foreign ownership. <br />
                  Specialized zones catering to specific industries such as
                  technology, media, finance, and logistics. <br />
                  Tax incentives and simplified customs procedures.
                </p>
              </div>
              {/* Offshore */}
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Offshores Companies</h1>
                </div>
                <p className="mb-4">
                  Ideal for international businesses not requiring a physical
                  presence in the UAE. <br />
                  Full repatriation of profits and capital. <br />
                  Complete confidentiality and asset protection.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/offshore.jpg"
                    alt="offshore business formation"
                    style={{ "object-fit": "fill" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dubai Business Formation Ends*/}
        {/* Process Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">Process</h5>
              <h1 className="mb-0">Our Siplified Company Formation Process</h1>
            </div>
            <div className="row g-5">
              <div className="col-md-1" />
              <div className="col-md-10" style={{ "min-height": "400px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/steps.svg"
                    style={{ "object-fit": "fill" }}
                  />
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
        {/* Process End */}
        {/* Get Started Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Ready?
                  </h5>
                  <h1 className="mb-0">Get Started Today</h1>
                </div>
                <p className="mb-4">
                  Embark on your UAE business journey with confidence and ease.
                  Contact OGM Business Consultants Consultants today for a
                  consultation, and let our experts guide you through the
                  seamless process of company formation in the UAE. Your success
                  begins with the right partner, and at OGM Business Consultants
                  Consultants, we are committed to being that partner for you.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/dubai-business-formation.jpg"
                    alt="dubai business formation"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Get Started End */}
        {/* Enquiry Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Have an Enquiry?
              </h5>
              <h1 className="mb-0">
                Do Not Hesitate To Contact Us For Your Free Consultation &amp;
                Quotations
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-md-3" />
              <div
                className=" col-md-6 col-md-offset-3 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <form action="https://formspree.io/f/meqyvylq" method="post">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        placeholder="First Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="test"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Last Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Email ID"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Contact Number"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        placeholder="What is your business activity?"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="number"
                        className="form-control border-0 bg-light px-4"
                        placeholder="How many visas will be required?"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <select
                        id="dropdown"
                        name="dropdown"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Select type of shareholder"
                        style={{ height: "55px" }}
                      >
                        <option value="option1">
                          Select type of shareholder...
                        </option>
                        <option value="option1">Individual</option>
                        <option value="option2">Corporate</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-3" />
            </div>
          </div>
        </div>
        {/* Enquiry End */}
        {/* Footer Start */}

        {/* Footer End */}
      </div>
    </Fragment>
  );
}
export default UaeCompany;
