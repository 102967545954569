import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function Quote() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ "margin-bottom": "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Book Your Free Consultation
              </h1>
              <a href className="h5 text-white">
                Home
              </a>
              <i className="far fa-circle text-white px-2" />
              <a href className="h5 text-white">
                Free Quote
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Quote Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Free Consultation
                  </h5>
                  <h1 className="mb-0">
                    Need A Consultation? Please Feel Free to Contact Us
                  </h1>
                </div>
                <div className="row gx-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-4">
                      <i className="fa fa-reply text-primary me-3" />
                      Reply within 24 hours
                    </h5>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-4">
                      <i className="fa fa-phone-alt text-primary me-3" />
                      24 hrs telephone support
                    </h5>
                  </div>
                </div>
                <p className="mb-4">
                  Book a free consultation with OGM Business Consultants today!
                  Our expert team is ready to assist you with your financial
                  needs. Take the first step towards financial success and
                  schedule your consultation now.
                </p>
                <div
                  className="d-flex align-items-center mt-2 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0">+971509860</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div
                  className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  <form action="https://formspree.io/f/meqyvylq" method="post">
                    <div className="row g-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control border-0 bg-light px-4"
                          name="f_name"
                          placeholder="First Name"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control border-0 bg-light px-4"
                          name="l_name"
                          placeholder="Last Name"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control border-0 bg-light px-4"
                          name="date"
                          placeholder="Company Name"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control border-0 bg-light px-4"
                          name="date"
                          placeholder="Email"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-12">
                        <select
                          className="form-select bg-light border-0"
                          style={{ height: "55px" }}
                        >
                          <option selected>Select A Service</option>
                          <option value={1}>Business Setup</option>
                          <option value={2}>Accounting &amp; Taxation</option>
                          <option value={3}>Statutory Compliance</option>
                          <option value={3}>Support</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="date"
                          className="form-control border-0 bg-light px-4"
                          name="date"
                          placeholder
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="time"
                          className="form-control border-0 bg-light px-4"
                          name="time"
                          placeholder
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          className="form-control bg-light border-0"
                          rows={3}
                          name="Message"
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-dark w-100 py-3"
                          type="submit"
                        >
                          Book
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Quote End */}
        {/* Vendor End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Quote;
