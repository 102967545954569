import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { Helmet } from "react-helmet";

function Payroll() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>Payroll Services in Dubai | Payroll Company in UAE </title>
          <meta
            name="description"
            content="Searching for Best Tax Consultants in Dubai ? OGM Consulatant as one of the top tax consultants and advisors in the UAE, offer dependable tax preparation services and tax planning in Dubai. To know more about us Contact us +971 50 986 0136"
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ "margin-bottom": "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                People &amp; Payrolls
              </h1>
              <a href="index.html" className="h5 text-white">
                Support
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">People &amp; Payrolls</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">People &amp; Payrolls</h1>
                </div>
                <p className="mb-4">
                  At O.G.M. Consultants, we recognize that effective
                  international HRM goes beyond simply replicating domestic HR
                  practices on a global scale. Our approach is rooted in a deep
                  understanding of the unique cultural, legal, and economic
                  environments in which our clients operate. We work closely
                  with organizations to develop customized HRM strategies that
                  align with their global business objectives while respecting
                  the local contexts in which they operate.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/people-and-payroll.jpg"
                    alt="best payroll service"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Blog Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container pt-5">
            <div
              className="section-title mb-5 pb-3 text-center position-relative mx-auto"
              style={{ "max-width": "800px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase" />
              <h1 className="mb-0">Key Services</h1>
            </div>
            <div className="row g-5 py-3 text-center position-relative mx-auto">
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fas fa-passport text-white" />
                  </div>
                  <h4 className="mb-3">Global Staffing and Recruitment</h4>
                  <p className="m-0">
                    We assist organizations in sourcing, selecting, and placing
                    talented individuals across different countries and
                    cultures. Our comprehensive approach ensures that
                    organizations have access to the right talent pool to meet
                    their international staffing needs.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">
                    Cross-Cultural Training and Development
                  </h4>
                  <p className="m-0">
                    We provide tailored training and development programs to
                    help employees thrive in multicultural work environments.
                    Our programs focus on building cultural competence,
                    communication skills, and cross-cultural collaboration to
                    drive organizational success.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-address-card text-white" />
                  </div>
                  <h4 className="mb-3">Global Compensation and Benefits</h4>
                  <p className="m-0">
                    We design competitive and compliant compensation and
                    benefits packages that attract and retain top talent while
                    ensuring compliance with local laws and customs. Our
                    expertise in global compensation management helps
                    organizations strike the right balance between consistency
                    and localization.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Expatriate Management</h4>
                  <p className="m-0">
                    We offer comprehensive support for employees on
                    international assignments, from pre-departure preparations
                    to repatriation. Our services include logistical support,
                    cultural adaptation training, and ongoing assistance to
                    ensure the success and well-being of expatriate employees.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Compliance and Legal Support</h4>
                  <p className="m-0">
                    We help organizations navigate the complex landscape of
                    international labor laws, regulations, and standards. Our
                    experts stay abreast of the latest developments in global
                    HRM regulations to ensure compliance and minimize legal
                    risks for our clients.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <h4 className="mb-3">Global Employee Relations</h4>
                  <p className="m-0">
                    We provide guidance and support on managing employee
                    relations issues across borders, helping organizations
                    foster positive workplace environments and navigate cultural
                    differences effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Payroll;
