import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function InternalControl() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary bg-header"
          style={{ marginBottom: "0px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Internal Control
              </h1>
              <a href="index.html" className="h5 text-white">
                Statutory Compliance
              </a>
              <i className="fa fa-arrow-right text-white px-2" />
              <a className="h5 fw-bold text-primary">Internal Control</a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Body Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container pt-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">OGM</h5>
                  <h1 className="mb-0">Highlight on Internal Control</h1>
                </div>
                <p className="mb-4">
                  <b>At O.G.M. Consultants,</b> we understand the importance of
                  robust internal controls in safeguarding assets, preventing
                  fraud, and enhancing operational efficiency within
                  organizations. Our Internal Control Solutions are tailored to
                  meet the unique needs and objectives of each client, helping
                  them establish and maintain effective control environments.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/internal_control1.jpg"
                    alt="internal control"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About us End */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container pt-5">
            <div className="row g-5 py-5">
              <div
                className="section-title text-center position-relative pb-3 my-5 mx-auto"
                style={{ maxWidth: "600px" }}
              >
                <h5 className="fw-bold text-primary text-uppercase" />
                <h1 className="mb-0">
                  Services We Offer Under Internal Control
                </h1>
              </div>
              <img
                className="position-absolute rounded wow zoomIn serviceimg"
                data-wow-delay="0.9s"
                src="assets/img/services_corporate.jpg"
                alt="audit and audit support"
              />
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-gear-wide-connected text-white" />
                  </div>
                  <h4 className="mb-3">Risk Assessment and Control Design</h4>
                  <p className="m-0">
                    We assist organizations in identifying and assessing risks
                    related to financial reporting, operations, and compliance.
                    Our experts design control frameworks tailored to mitigate
                    identified risks effectively while optimizing operational
                    efficiency.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-diagram-2 text-white" />
                  </div>
                  <h4 className="mb-3">
                    Process Documentation and Optimization
                  </h4>
                  <p className="m-0">
                    We help organizations document and streamline their business
                    processes to enhance transparency, accountability, and
                    consistency. Our consultants identify inefficiencies and
                    design process improvements to strengthen internal controls
                    and maximize productivity.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-list-check text-white" />
                  </div>
                  <h4 className="mb-3">Segregation of Duties Analysis</h4>
                  <p className="m-0">
                    Segregation of duties is crucial for preventing fraud and
                    errors. We conduct thorough analyses of organizational roles
                    and responsibilities to identify opportunities for
                    segregation of duties and reduce the risk of unauthorized
                    activities.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-bar-chart-line text-white" />
                  </div>
                  <h4 className="mb-3">Internal Audit Support</h4>
                  <p className="m-0">
                    Our consultants provide internal audit support, including
                    risk-based audit planning, execution, and reporting. We help
                    organizations establish internal audit functions or enhance
                    existing ones to provide independent assurance on the
                    effectiveness of internal controls.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
                bi
                bi-book
              >
                <div
                  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="service-icon">
                    <i className="bi bi-book text-white" />
                  </div>
                  <h4 className="mb-3">Training and Awareness Programs</h4>
                  <p className="m-0">
                    We offer training and awareness programs to educate
                    employees on the importance of internal controls, their
                    roles and responsibilities, and best practices for
                    maintaining a strong control environment. These programs
                    empower employees to be active participants in safeguarding
                    organizational assets and integrity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Starts */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container pt-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h1 className="mb-0">FAQs </h1>
                </div>
                <h5 className="fw-bold text-primary text-uppercase">
                  What are internal controls, and why are they important?
                </h5>
                <p className="mb-5">
                  Internal controls are processes, policies, and procedures
                  designed to safeguard assets, ensure accuracy and reliability
                  of financial reporting, and promote compliance with laws and
                  regulations. They are important because they help
                  organizations mitigate risks, prevent fraud, and enhance
                  operational efficiency.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How can O.G.M. Consultants help improve our organization's
                  internal controls?
                </h5>
                <p className="mb-5">
                  Our consultants provide a range of services, including risk
                  assessment, control design, process optimization, segregation
                  of duties analysis, internal audit support, and training
                  programs. We work collaboratively with your organization to
                  identify control weaknesses and implement tailored solutions
                  to strengthen the control environment.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How often should our organization assess its internal
                  controls?
                </h5>
                <p className="mb-5">
                  Internal controls should be assessed regularly to ensure they
                  remain effective in mitigating risks and achieving
                  organizational objectives. The frequency of assessments may
                  vary depending on factors such as changes in business
                  processes, regulatory requirements, and the risk environment.
                  Typically, organizations conduct internal control assessments
                  annually or biennially.
                </p>
                <h5 className="fw-bold text-primary text-uppercase">
                  How can we measure the effectiveness of our internal controls?
                </h5>
                <p className="mb-5">
                  The effectiveness of internal controls can be measured through
                  various methods, including control testing, internal audit
                  reviews, and self-assessments. Key indicators of effective
                  internal controls include adherence to policies and
                  procedures, timely detection and resolution of control
                  deficiencies, and compliance with regulatory requirements.
                  <br />
                  At O.G.M. Consultants, we are dedicated to helping
                  organizations build resilient, efficient, and integrity-driven
                  internal control environments. Contact us today to learn more
                  about how our Internal Control Solutions can benefit your
                  organization.
                </p>
              </div>
              <div className="col-lg-5" style={{ minHeight: "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/faq.jpg"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Ends */}
        {/* Blog End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default InternalControl;
